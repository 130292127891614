import axios from "axios";
import { getLocal, getSession } from "../utils/localStorage.utils";
const token = getLocal('token') || getSession('token')

const hostname = window.location.hostname;
let APP_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
switch(hostname) {
	case "admintrial.trialclouds.com":
		APP_BASE_URL = process.env.REACT_APP_API_ENDPOINT_TRIALCLOUDS;
		break;
	case "adminff.ffshopbd.com":
		APP_BASE_URL = process.env.REACT_APP_API_ENDPOINT_FFSHOPBD;
		break;
	default:
			
}

const axiosInstance = axios.create({
    baseURL: APP_BASE_URL,
    headers: {
        authorization: token
    }
});
export default axiosInstance;